<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <reject-desc-modal ref="rejectDescRef" @submit="onStatusChange" />
    <MyDataTable
      title="مدیریت درخواست برداشت ها"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      :isRemoveEnabled="false"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onStatusChange(row, 1)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          قبول
        </v-btn>
        <v-btn
          class="mx-2"
          dark
          small
          color="red"
          @click="onReject(row, 2)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          رد
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>
          {{
            row.user && row.user.first_name && row.user.last_name
              ? row.user.first_name + " " + row.user.last_name
              : "-"
          }}
        </td>
        <td>
          {{ row.price ? Intl.NumberFormat().format(row.price) : 0 }}
        </td>
        <td>{{ row.date | moment("jYYYY/jMM/jDD") }}</td>
        <td>{{ row.formatted_status }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import RejectDescModal from "../../../../components/Panel/RejectDescModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    RejectDescModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/withdraws/index", {
        params: {
          with: "user",
          page: this.$route.query?.page || 1,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/withdraws/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.$router.push({
        name: "Sources",
        params: {
          parentId: item.id,
        },
      });
    },
    onReject(row, status) {
      this.$refs.rejectDescRef.onOpen(row, status);
    },
    onStatusChange(row, status, status_desc = "") {
      this.loading = true;
      MyAxios.post("/withdraws/" + row.id + "/update", {
        status, // check => now it will set status to 4 if withdraw is accepted
        status_desc,
      })
        .then((response) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "وضعیت برداشت با موفقیت تغییر کرد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      MyAxios.delete(`/withdraws/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/withdraws/index",
      pathParams: {
        with: "user",
      },
      headers: [
        {
          text: "کاربر",
          align: "start",
          value: "user_id",
        },
        { text: "قیمت", value: "price" },
        { text: "تاریخ", value: "date" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
