<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="modal" width="600px">
      <v-card class="text-center rounded-lg">
        <v-card-text>
          <v-form ref="form" @submit.prevent="submit">
            <v-row justify="center">
              <v-col md="9" cols="12">
                <h1 class="orange--text darken-1 my-8">
                  توضیحات خود را بنویسید. (اختیاری)
                </h1>
                <v-form-base
                  :row="rowAttribute"
                  :col="{ cols: 12 }"
                  :model="model"
                  :schema="schema"
                />
                <v-btn
                  block
                  large
                  dark
                  color="success"
                  :loading="loading"
                  type="submit"
                >
                  ثبت
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";
import VFormBase from "vuetify-form-base";

export default {
  components: { VFormBase },
  props: {
    vendor_id: {
      type: String | Number,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      loading: false,
      rules: Constants.rules,
      row: {},
      status: undefined,
      model: {
        desc: "",
      },
      schema: {
        desc: {
          type: "textarea",
          solo: true,
          flat: true,
          label: "توضیحات وضعیت",
          backgroundColor: "grey lighten-2",
          class: "required rounded-lg",
        },
      },
    };
  },
  watch: {
    modal(val) {
      if (val === false) {
        this.row = {};
        this.status = undefined;
      }
    },
  },
  methods: {
    onOpen(row, status) {
      this.row = row;
      this.status = status;
      this.modal = true;
    },
    onClose() {
      this.modal = false;
    },
    submit() {
      this.$emit("submit", this.row, this.status, this.model.desc);
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ico-shadow {
  box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>
